import React from 'react';
import { Typography } from 'radiance-ui';
import { css } from '@emotion/react';
import { SPACER, ThemeType } from 'radiance-ui/lib/constants';

const captionStyles = (theme: ThemeType) => css`
  margin-top: ${SPACER.medium};
  font-family: ${theme.FONTS.baseFont};
`;

interface DateModifiedProps {
  data: {
    date: {
      withMonthName: string;
      withoutMonthName: string;
    };
  };
}

const DateModified = ({ data }: DateModifiedProps) => (
  <time dateTime={data.date.withoutMonthName}>
    <Typography.Caption css={captionStyles}>
      Last Updated: {data.date.withMonthName}
    </Typography.Caption>
  </time>
);

export default DateModified;
