import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Button } from 'radiance-ui';
import styled from '@emotion/styled';

import APP_URLS from 'consts/appUrls';
import COOKIE_KEYS from 'consts/cookieKeys';
import cookie from 'utils/cookie';
import { hasAuth } from 'utils/auth';

import { Paragraph } from './style';

const OptOutButtonContainer = styled.div`
  text-align: left;
`;

const DoNotSellButton = () => {
  const [hasTrackingOptedOut, setHasTrackingOptedOut] = useState(false);

  const getTrackingOptedOut = () =>
    cookie.get(COOKIE_KEYS.hasTrackingOptedOut) === 'true';

  useEffect(() => {
    setHasTrackingOptedOut(getTrackingOptedOut());
  }, []);

  const optOutUser = (event: SyntheticEvent<HTMLButtonElement, Event>) => {
    event.preventDefault();

    cookie.set(COOKIE_KEYS.hasTrackingOptedOut, 'true');
    setHasTrackingOptedOut(true);

    if (hasAuth()) {
      window.location.href = `${APP_URLS.trackingOptOut}?redirectUrl=${window.location.href}`;
    } else {
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    }
  };

  return (
    <OptOutButtonContainer>
      <Button
        id="doNotSell_trackingOptOutButton"
        buttonType="primary"
        disabled={hasTrackingOptedOut}
        onClick={optOutUser}
        role="button"
        aria-pressed={hasTrackingOptedOut}
      >
        Don’t Sell My Info
      </Button>
      {hasTrackingOptedOut && (
        <Paragraph>Confirmed! Your data will not be shared.</Paragraph>
      )}
    </OptOutButtonContainer>
  );
};

export default DoNotSellButton;
