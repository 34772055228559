import React from 'react';
import { graphql } from 'gatsby';
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';

import SEO from 'components/global/seo';

import { LEGAL_PAGE_RICH_TEXT_STYLES } from './style';
import { enrichBodyContent } from './utils/enrichBodyContent';
import { DoNotSellButton, Layout } from './components';

interface LegalPageTemplateProps {
  data: {
    contentfulLegalPage: {
      title: string;
      slug: string;
      bodyContent: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      seoTitle: string;
      dateModifiedWithMonthName: string;
      dateModifiedWithoutMonthName: string;
    };
  };
}

const LegalPageTemplate = ({ data }: LegalPageTemplateProps) => {
  const {
    contentfulLegalPage: {
      slug,
      bodyContent,
      seoTitle,
      dateModifiedWithMonthName,
      dateModifiedWithoutMonthName,
    },
  } = data;

  const richBodyContent = enrichBodyContent({
    bodyContent,
    dateModified: {
      withMonthName: dateModifiedWithMonthName,
      withoutMonthName: dateModifiedWithoutMonthName,
    },
  });

  const isDoNotSellPage = slug === 'donotsell';

  return (
    <React.Fragment>
      <SEO title={`Agency - ${seoTitle}`} />
      <Layout shouldShowNav={!isDoNotSellPage}>
        {richBodyContent &&
          renderRichText(richBodyContent, LEGAL_PAGE_RICH_TEXT_STYLES)}
        {isDoNotSellPage && <DoNotSellButton />}
      </Layout>
    </React.Fragment>
  );
};

export const query = graphql`
  query LegalPageQuery($legalPageId: String!) {
    contentfulLegalPage(id: { eq: $legalPageId }) {
      title
      slug
      seoTitle
      dateModifiedWithMonthName: updatedAt(formatString: "MMMM DD, YYYY")
      dateModifiedWithoutMonthName: updatedAt(formatString: "YYYY-MM-DD")
      bodyContent {
        raw
      }
    }
  }
`;

export default LegalPageTemplate;
