import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby';
import { SPACER, MEDIA_QUERIES } from 'radiance-ui/lib/constants';

export const NavContainer = styled.nav`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: ${SPACER.medium} 0 ${SPACER.xlarge} 0;

  ${MEDIA_QUERIES.mdUp} {
    margin: ${SPACER.medium} 0;
  }
`;

export const Link = styled(GatsbyLink)`
  font-family: ${({ theme }) => theme.FONTS.headerFont};
  color: ${({ theme }) => theme.COLORS.textDisabled};
  margin: ${SPACER.xsmall} 0;
  text-decoration: none;

  ${MEDIA_QUERIES.mdUp} {
    font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.body};
  }
`;

export const activeLinkStyle = {
  fontFamily: 'quadrant-text-italic',
  color: 'inherit',
};
