import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACER, MEDIA_QUERIES, GRID } from 'radiance-ui/lib/constants';

export const MainContainer = styled.div`
  padding: 3rem ${SPACER.xlarge} ${SPACER.x4large} ${SPACER.xlarge};
  margin: 0 auto;
  max-width: ${GRID.xlContentMaxWidth};

  ${MEDIA_QUERIES.mdUp} {
    padding-top: 7rem;
    padding-right: ${SPACER.xlarge};
  }
`;

export const SideNavContainer = styled.div`
  flex: 1;
  ${MEDIA_QUERIES.mdUp} {
    padding: 0 10px;
  }
`;

export const ContentContainer = styled.div<{ shouldCenterContent?: boolean }>`
  flex: 4;
  margin: 0 auto;

  max-width: ${({ shouldCenterContent }) =>
    shouldCenterContent ? '900px' : GRID.xlContentMaxWidth};

  ${MEDIA_QUERIES.mdUp} {
    padding-left: ${({ shouldCenterContent }) =>
      shouldCenterContent ? 0 : SPACER.x5large};
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;

  ${MEDIA_QUERIES.mdUp} {
    flex-flow: row wrap;
  }
`;

export const LegalHeading = styled.h1`
  font-family: ${({ theme }) => theme.FONTS.headerFont};
  font-size: 4.25rem;
  font-weight: normal;
`;

export const dividerStyles = css`
  ${MEDIA_QUERIES.xsUp} {
    margin: ${SPACER.medium} 0 ${SPACER.x3large} 0;
  }
`;
