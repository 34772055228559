import React from 'react';

import PAGE_URLS from 'consts/pageUrls';

import { NavContainer, Link, activeLinkStyle } from './style';

const SideNav = () => (
  <NavContainer>
    <Link activeStyle={activeLinkStyle} to={PAGE_URLS.terms}>
      Terms of Service
    </Link>
    <Link activeStyle={activeLinkStyle} to={PAGE_URLS.disclaimer}>
      Disclaimer
    </Link>
    <Link activeStyle={activeLinkStyle} to={PAGE_URLS.privacy}>
      Privacy Notice
    </Link>
    <Link activeStyle={activeLinkStyle} to={PAGE_URLS.telehealthConsent}>
      Telehealth Consent
    </Link>
    <Link
      activeStyle={activeLinkStyle}
      to={PAGE_URLS.guardianTelehealthConsent}
    >
      Parent/Guardian Telehealth Consent
    </Link>
  </NavContainer>
);

export default SideNav;
