import { Block, Text, BLOCKS, Inline } from '@contentful/rich-text-types';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';

interface DateModifiedType {
  withMonthName: string;
  withoutMonthName: string;
}

type HeaderNodeType = (Block | Inline) & {
  content: [Text];
};

/* 
  Returns an object with key "id" whos value is an all lowercase, hyphenated string containing only alphabetical characters.
  headerId ex: "1. Header Title" -> 'header-title'
*/
export const getIdPropFromHeaderNode = ({ node }: { node: Block | Inline }) => {
  const headerTextNode = (node as HeaderNodeType)?.content[0];
  const headerText = headerTextNode?.value;
  const headerId =
    headerText && headerText.length < 100
      ? headerText
          .replace(/[^a-zA-Z-\s]/g, '')
          .trim()
          .replace(/\s+/g, '-')
          .toLowerCase()
      : null;
  return headerId ? { id: headerId } : null;
};

/* 
  Returns all content nodes after supplying list-item nodes with a "parentType" property. 
  This allows us to differentiate between ordered and unordered list-items in the rich text styles object.
*/
const supplyListItemNodesWithParentType = ({
  richContentNodes,
}: {
  richContentNodes: Block[];
}) => {
  return richContentNodes.map((node) => {
    const { nodeType, content } = node;
    if (nodeType?.includes('-list') && content?.length > 0) {
      const contentNodesWithParentType = content.map((childNode) => ({
        ...childNode,
        parentType: nodeType,
      }));
      return {
        ...node,
        content: contentNodesWithParentType,
      };
    }
    return node;
  });
};

/* 
  Returns all content nodes after inserting the "dateModified" Node at index 1.
  Since the title of the document will always be at index 0, this inserts the "dateModified" text below the title.
*/
const insertDateModifiedNode = ({
  richContentNodes,
  dateModified,
}: {
  richContentNodes: Block[];
  dateModified: DateModifiedType;
}) => {
  const dateModifiedNode: Block = {
    nodeType: BLOCKS.EMBEDDED_ENTRY,
    data: {
      target: {
        __typename: 'DateModified',
        date: dateModified,
      },
    },
    content: [],
  };
  const contentNodesWithModifiedDate = [...richContentNodes];
  contentNodesWithModifiedDate.splice(1, 0, dateModifiedNode);
  return contentNodesWithModifiedDate;
};

export const enrichBodyContent = ({
  bodyContent,
  dateModified,
}: {
  bodyContent: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  dateModified: DateModifiedType;
}): RenderRichTextData<ContentfulRichTextGatsbyReference> => {
  const parsedNodes = JSON.parse(bodyContent.raw);
  let richContentNodes = parsedNodes?.content;
  if (richContentNodes) {
    richContentNodes = supplyListItemNodesWithParentType({ richContentNodes });
    richContentNodes = insertDateModifiedNode({
      richContentNodes,
      dateModified,
    });
  }

  const enrichedBodyContent = {
    ...parsedNodes,
    ...{ content: richContentNodes },
  };

  return {
    ...bodyContent,
    raw: JSON.stringify(enrichedBodyContent),
  };
};
