import React from 'react';
import { Container } from 'radiance-ui';

import Nav from 'components/global/nav';
import SiteFooter from 'components/global/footer';

import SideNav from './components/sideNav';
import {
  MainContainer,
  InnerContainer,
  SideNavContainer,
  ContentContainer,
  LegalHeading,
  dividerStyles,
} from './style';

interface LayoutProps {
  children: React.ReactNode;
  shouldShowNav?: boolean;
}

const Layout = ({ children, shouldShowNav = true }: LayoutProps) => (
  <React.Fragment>
    <Nav />
    <MainContainer>
      <LegalHeading>Our Policies</LegalHeading>
      <Container.Divider css={dividerStyles} />
      <InnerContainer>
        {shouldShowNav && (
          <SideNavContainer>
            <SideNav />
          </SideNavContainer>
        )}
        <ContentContainer shouldCenterContent={!shouldShowNav}>
          {children}
        </ContentContainer>
      </InnerContainer>
    </MainContainer>
    <SiteFooter />
  </React.Fragment>
);

export default Layout;
