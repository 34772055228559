import React from 'react';
import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';
import { Container, Typography, TYPOGRAPHY_STYLE } from 'radiance-ui';
import { Block, BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';

import { DateModified } from './components';
import { getIdPropFromHeaderNode } from './utils/enrichBodyContent';

export const PageHeading = styled.h1`
  font-size: 5rem;
  font-family: ${({ theme }) => theme.FONTS.headerFont};
  margin: 0 2.5rem;
`;

export const Divider = styled(Container.Divider)`
  margin: ${SPACER.xlarge} 0;
`;

export const Title = styled(Typography.Display)`
  font-weight: normal;
`;

export const Heading = styled(Typography.Heading)`
  font-family: ${({ theme }) => theme.FONTS.baseFont};
  margin-top: ${SPACER.x2large};
`;

export const SubHeading = styled(Typography.Title)`
  font-family: ${({ theme }) => theme.FONTS.baseFont};
  margin-top: ${SPACER.xlarge};
`;

export const Paragraph = styled.p`
  ${({ theme }) => TYPOGRAPHY_STYLE.body(theme)};
  font-family: ${({ theme }) => theme.FONTS.baseFont};
  margin: ${SPACER.xlarge} 0;
  word-break: break-word;
`;

const ListItemBase = styled.li`
  > p {
    display: inline;
    margin: unset;
  }
`;

export const UnorderedList = styled.ul`
  padding-left: ${SPACER.large};
  list-style: disc;
  margin: ${SPACER.medium} 0px 0px ${SPACER.medium};
`;

export const UnorderedListItem = styled(ListItemBase)`
  margin: ${SPACER.medium} 0px 0px;
  text-indent: 0px;
`;

export const OrderedList = styled.ol`
  margin: ${SPACER.large} 0px 0px ${SPACER.large};
  counter-reset: item 0;
  padding: 0px;
`;

export const OrderedListItem = styled(ListItemBase)`
  text-indent: -${SPACER.large};
  list-style-type: none;
  counter-increment: item 1;
  padding: 0px 0px 0px ${SPACER.large};
  margin: ${SPACER.medium} 0px 0px;

  :before {
    display: inline-block;
    padding-right: ${SPACER.medium};
    font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};
    text-align: right;
    content: counter(item) '.';
    width: ${SPACER.large};
  }
`;

type BlockWithParentType = Block & {
  parentType?: string;
};

const EMBEDDED_ENTRY_COMPONENTS: Record<
  string,
  typeof DateModified | undefined
> = {
  DateModified,
};

// Prop spreading in heading components is necessary to avoid passing in an empty id
export const LEGAL_PAGE_RICH_TEXT_STYLES: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => <Title>{children}</Title>,
    [BLOCKS.HEADING_2]: (node, children) => (
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      <Heading {...getIdPropFromHeaderNode({ node })}>{children}</Heading>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      <SubHeading {...getIdPropFromHeaderNode({ node })}>{children}</SubHeading>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.OL_LIST]: (_node, children) => (
      <OrderedList>{children}</OrderedList>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => {
      const ListItem =
        (node as BlockWithParentType)?.parentType === 'ordered-list'
          ? OrderedListItem
          : UnorderedListItem;
      return <ListItem>{children}</ListItem>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, _children) => {
      // eslint-disable-next-line react/destructuring-assignment
      const componentType = node?.data?.target?.__typename;
      // eslint-disable-next-line react/destructuring-assignment
      const componentData = node?.data?.target;
      const Component =
        componentType && EMBEDDED_ENTRY_COMPONENTS[componentType];
      return Component && componentData ? (
        <Component data={componentData} />
      ) : null;
    },
  },
};
