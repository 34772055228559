import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const Paragraph = styled.p`
  ${({ theme }) => TYPOGRAPHY_STYLE.body(theme)};
  font-family: ${({ theme }) => theme.FONTS.baseFont};
  margin: ${SPACER.xlarge} 0;
  word-break: break-word;
`;
